<template>
  <div class="sm:rounded-lg">
    <div class="px-4 py-5 sm:p-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">404 Not Found</h3>
      <div class="mt-2 max-w-xl text-sm text-gray-500">
        <p>The page you are looking for was not found.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
